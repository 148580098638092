import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { data } from "./data";
import styled from "styled-components";

const SocialLink = styled.a``;

interface SocialIconProps {
  pandoraIconWidth?: string;
  iconWidth?: string;
}

export default function SocialIcons({
  iconWidth,
  pandoraIconWidth,
}: SocialIconProps) {
  return (
    <ul className="flex flex-col justify-center items-center text-white">
      {data.map((item, idx) => (
        <SocialLink
          key={idx}
          href={item.link}
          rel="noreferrer"
          target="_blank"
          className="hover:text-primary text-2xl mb-2 rounded-lg w-10 h-10 flex justify-center items-center"
        >
          {item.icon ? (
            <FontAwesomeIcon icon={item.icon} />
          ) : item.isPandora ? (
            <img
              src={item.img}
              className={pandoraIconWidth + "hidden"}
              style={{
                filter:
                  "invert(8%) sepia(99%) saturate(6379%) hue-rotate(355deg) brightness(90%) contrast(94%)",
              }}
            />
          ) : (
            <img
              src={item.img}
              className={iconWidth + "hidden"}
              style={{
                filter:
                  "invert(8%) sepia(99%) saturate(6379%) hue-rotate(355deg) brightness(90%) contrast(94%)",
              }}
            />
          )}
        </SocialLink>
      ))}
    </ul>
  );
}
