import { RoutePattern } from "../../routes/RoutePattern";

export const data = [
  {
    name: RoutePattern.HOME,
    to: RoutePattern.HOME,
    isRoute: true,
  },
  // {
  //   name: RoutePattern.RSVP,
  //   to: RoutePattern.RSVP,
  //   isRoute: true,
  // },
  // {
  //   name: "Emerging Talent",
  //   to: RoutePattern.EMERGING_TALENT,
  //   isRoute: true,
  // },
  // {
  //   name: "Guest Artists",
  //   to: RoutePattern.GUESTS,
  //   isRoute: true,
  // },
];
