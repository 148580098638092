import IMG_1 from "../../../assets/images/Young Thug Marquee Images/Young Thug 1.png";
import IMG_2 from "../../../assets/images/Young Thug Marquee Images/Young Thug 2.png";
import IMG_3 from "../../../assets/images/Young Thug Marquee Images/Young Thug 3.png";
import IMG_4 from "../../../assets/images/Young Thug Marquee Images/Young Thug 4.png";
import IMG_5 from "../../../assets/images/Young Thug Marquee Images/Young Thug 5.png";
import IMG_6 from "../../../assets/images/Young Thug Marquee Images/Young Thug 6.png";
import IMG_7 from "../../../assets/images/Young Thug Marquee Images/Young Thug 7.png";
import IMG_8 from "../../../assets/images/Young Thug Marquee Images/Young Thug 8.png";
import IMG_9 from "../../../assets/images/Young Thug Marquee Images/Young Thug 9.png";
import IMG_10 from "../../../assets/images/Young Thug Marquee Images/Young Thug 10.png";
import IMG_11 from "../../../assets/images/Young Thug Marquee Images/Young Thug 11.png";
import IMG_12 from "../../../assets/images/Young Thug Marquee Images/Young Thug 12.png";
import IMG_13 from "../../../assets/images/Young Thug Marquee Images/Young Thug 13.png";
import IMG_14 from "../../../assets/images/Young Thug Marquee Images/Young Thug 14.png";
import IMG_15 from "../../../assets/images/Young Thug Marquee Images/Young Thug 15.png";
import IMG_16 from "../../../assets/images/Young Thug Marquee Images/Young Thug 16.png";
import IMG_17 from "../../../assets/images/Young Thug Marquee Images/Young Thug 17.png";
import IMG_18 from "../../../assets/images/Young Thug Marquee Images/Young Thug 18.png";
import IMG_19 from "../../../assets/images/Young Thug Marquee Images/Young Thug 19.png";
import IMG_20 from "../../../assets/images/Young Thug Marquee Images/Young Thug 20.png";
import IMG_21 from "../../../assets/images/Young Thug Marquee Images/Young Thug 21.png";
import IMG_22 from "../../../assets/images/Young Thug Marquee Images/Young Thug 22.png";
import IMG_23 from "../../../assets/images/Young Thug Marquee Images/Young Thug 23.png";
import IMG_24 from "../../../assets/images/Young Thug Marquee Images/Young Thug 24.png";
import IMG_25 from "../../../assets/images/Young Thug Marquee Images/Young Thug 25.png";
import IMG_26 from "../../../assets/images/Young Thug Marquee Images/Young Thug 26.png";

export const youngthugPhotos = [
  {
    img: IMG_1,
  },
  {
    img: IMG_2,
  },
  {
    img: IMG_3,
  },
  {
    img: IMG_4,
  },
  {
    img: IMG_5,
  },
  {
    img: IMG_6,
  },
  {
    img: IMG_7,
  },
  {
    img: IMG_8,
  },
  {
    img: IMG_9,
  },
  {
    img: IMG_10,
  },
  {
    img: IMG_11,
  },
  {
    img: IMG_12,
  },
  {
    img: IMG_13,
  },
  {
    img: IMG_14,
  },
  {
    img: IMG_15,
  },
  {
    img: IMG_16,
  },
  {
    img: IMG_17,
  },
  {
    img: IMG_18,
  },
  {
    img: IMG_19,
  },
  {
    img: IMG_20,
  },
  {
    img: IMG_21,
  },
  {
    img: IMG_22,
  },
  {
    img: IMG_23,
  },
  {
    img: IMG_24,
  },
  {
    img: IMG_25,
  },
  {
    img: IMG_26,
  },
];
