import styled from "styled-components";
import COVER_PHOTO from "../../../assets/images/Young Thug bday desktop cover img.webp";
import Button from "../../../components/Form/Button";
import { Box } from "../../../components/StyledComponent";
import { RoutePattern } from "../../../routes/RoutePattern";
import { CriOS, iOS } from "../../../util";
import Marquee from "../../../components/Marquee/Marquee";
import { youngthugPhotos } from "./data";
// import { isMobile } from "../../../constants";

const CoverContainer = styled.div`
  // @media (max-width: 820px) {
  //   left: 50%;
  //   top: 50%;
  //   transform: translate(-50%,-50%);
  //   position: absolute;
  // }
`;

const ImgLogo = styled.img`
  width: 90vw;
  height: 40vh;
  object-fit: cover;
  margin: 0 auto;

  @media (min-width: 768px) {
    width: 20vw;
    height: auto;
  }

  @media (min-width: 1024px) {
    height: 50vh;
  }
`;

const FileCapture = styled.div``;

const Showcase = () => {
  // const [filePath, setFilePath] = useState("");
  const handleClick = () => {
    window.open(
      "https://youngthug.ffm.to/businessisbusinessmetro?fbclid=PAAaaY6_ulaKyEA3nIEONzhwDiYQYy7FbJ7Kyr5pLxQmrYHWdqrvHvCyGkTw0_aem_AbXzLYKJl1Sf7jILeadHsK-h2fhe8Bj2E1fXiBBwpghx8Zd5mjkQ1GWeIWIksSrwCao"
    );
  };

  return (
    <Box
      id={RoutePattern.HOME}
      className={`relative flex flex-col justify-center items-center lg:h-screen w-full overflow-hidden py-[2vh] `}
    >
      <Box className="z-30 flex flex-col justify-center items-center h-full w-full">
        <CoverContainer className="flex flex-col h-auto lg:h-full w-full justify-center">
          <h1 className="font-bib text-[12vh] md:text-[20vh] leading-[4vh] md:leading-[7vh] text-center">
            YOUNG THUG, <br />
          </h1>
          <h2 className="mb-[2vh] font-bib text-[6vh] md:text-[10vh] leading-[2vh] md:leading-[3vh] text-center">
            YOUR MUSIC, TALENT, AND VISION <br className="hidden md:block" />
            ARE GIFTS TO US
          </h2>
          {/* <h1 className="block my-[2vh] font-bib text-[7vh] md:text-[10vh] leading-[2vh] md:leading-[10vh] text-center"></h1> */}
          <div>
            <Marquee>
              {youngthugPhotos.map((item, idx) => (
                <ImgLogo
                  key={idx}
                  src={item.img}
                  onClick={handleClick}
                  className="cursor-pointer"
                ></ImgLogo>
              ))}
            </Marquee>
          </div>
          {/* <ImgLogo
            src={COVER_PHOTO}
            onClick={handleClick}
            className="cursor-pointer"
          ></ImgLogo> */}
          <div className="text-[2.5vh] leading-[3vh] md:text-[3vh] md:leading-[3.5vh] w-full md:w-1/3 text-center pt-[3vh] px-[3vh] md:mx-auto">
            WISH YOUNG THUG A HAPPY BIRTHDAY. SUBMIT YOUR VIDEO BEFORE AUGUST
            16TH AT 11:59PM.
          </div>
          <FileCapture className="text-center">
            <input
              type="file"
              name="File_Path"
              placeholder="Upload video file "
              className="text-[2.5vh] mt-[3vh] p-[.5vh] md:p-[.5vh] w-2/3 md:w-1/4 m-auto rounded-sm"
              style={{
                fontFamily: "League Spartan Thin",
                fontWeight: 600,
                color: "#000",
                backgroundColor: "#fff",
              }}
              accept="video/mp4,video/x-m4v,video/*"
            />
            <div className="mt-[3vh]">
              <Button id="submit-video" type="submit">
                <span className="m-auto">Submit</span>
              </Button>
            </div>
          </FileCapture>
        </CoverContainer>
      </Box>
    </Box>
  );
};

export default Showcase;
