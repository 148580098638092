import { useRef } from "react";
import "./marquee.css";

const Marquee = ({ ...props }) => {
  const ref = useRef<any>(null);

  return (
    <div ref={ref} className="z-10">
      <div className="marquee">
        <div className="marquee__inner" aria-hidden="true">
          {props.children}
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Marquee;
