import { Box } from "../../components/StyledComponent";
import { CriOS, iOS } from "../../util";
import Showcase from "./Showcase/Showcase";

export default function Home() {
  return (
    <Box
      className={`${CriOS ? "h-[88vh]" : iOS ? "h-[91vh]" : "h-[93vh]"}  
    text-white lg:h-screen w-full flex flex-col justify-center items-center`}
    >
      <Showcase />
    </Box>
  );
}
